<template>
    <v-dialog
      v-model="value"
      persistent
      max-width="50%"
    >
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col>
              <span class="headline">Fiware Registration</span>
            </v-col>
            <v-col class="text-right">
              <v-btn
                x-small
                icon
                @click="isJson = !isJson"
              >
                <v-icon>mdi-code-json</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="!isJson">
          <v-container>
            <fiware-registration-form :registration="registrationForm" @action="actionRegistrationForm($event)" ref="registration"></fiware-registration-form>
          </v-container>
        </v-card-text>
        <v-card-text v-else>
          <v-container>
            <v-jsoneditor v-model="filterRegistrationForm" :options="{ mode: 'code' }" :plus="false" height="400px" @error="jsoneditorError($event)"></v-jsoneditor>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="blue darken-1"
            text
            @click="isCancel = true"
          >
            Close
          </v-btn>
          <confirm-dialog v-model="isCancel" type="cancel" title="fiware registration" @confirm="confirmCancelRegistration()"></confirm-dialog>
          <v-btn
            small
            color="blue darken-1"
            text
            @click="createRegistration()"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

var registration = {
  id: null,
  description: null,
  provider: {
    url: null,
    supportedForwardingMode: null
  },
  dataProvided: {
    entities: [{
      key: {
        name: null,
        value: null
      },
      value: {
        name: null,
        value: null
      }
    }],
    attrs: {
      values: [],
      inputValue: null
    },
    expression: [
      {
        key: null,
        value: null
      }
    ],
    expressionz: null
  },
  status: null,
  expires: null,
  forwardingInformation: {
    timesSent: null,
    lastForwarding: null,
    lastFailure: null,
    lastSuccess: null
  }
}

Object.freeze(registration)

export default {
  name: 'NewRegistration',
  components: {
    FiwareRegistrationForm: () => import('@/components/fiware/FiwareRegistrationForm'),
    ConfirmDialog: () => import('@/components/ConfirmDialog')
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    valid: true,
    isCancel: false,
    isJson: false,
    registrationForm: JSON.parse(JSON.stringify(registration))
  }),
  methods: {
    jsoneditorError: function (error) {
      console.log(error)
    },
    confirmCancelRegistration: function () {
      this.$emit('input', false)
      if (!this.isJson) this.$refs.registration.resetValidateForm()
      this.registrationForm = JSON.parse(JSON.stringify(registration))
    },
    createRegistration: function () {
      this.$refs.registration.createRegistration()
    },
    actionRegistrationForm: function () {
      this.$emit('input', false)
      this.$emit('fetch')
      this.registrationForm = JSON.parse(JSON.stringify(registration))
      this.$refs.registration.resetValidateForm()
    }
  },
  computed: {
    commonRules: function () {
      return [
        v => !!v || 'field is required',
        v => (v && v.length <= 50) || 'field must be less than 50 characters'
      ]
    },
    iso8601Rules: function () {
      return [
        v => !!v || 'field is required',
        v => new Date(v).toJSON() === v || 'field not match ISO8601 format'
      ]
    },
    numberRules: function () {
      return [
        v => !!v || 'field is required',
        v => /^(-?\d+\.\d+)$|^(-?\d+)$/i.test(v) || 'field must be number'
      ]
    },
    filterRegistrationForm: {
      get () {
        const registration = {
          provider: {
            http: {
              url: this.registrationForm.provider.url
            }
          },
          dataProvided: {
            entities: []
          }
        }
        if (this.registrationForm.description) {
          registration.description = this.registrationForm.description
        }
        if (this.registrationForm.status) {
          registration.status = this.registrationForm.status
        }
        if (this.registrationForm.expires) {
          registration.expires = this.registrationForm.expires
        }
        if (this.registrationForm.provider.supportedForwardingMode) {
          registration.provider.supportedForwardingMode = this.registrationForm.provider.supportedForwardingMode
        }
        if (this.registrationForm.dataProvided.entities.filter(entitie => entitie.key.value && entitie.value.value).length > 0) {
          let entities = []
          this.registrationForm.dataProvided.entities.filter(entitie => entitie.key.value && entitie.value.value).forEach(entitie => {
            entities.push({ [entitie.key.name]: entitie.key.value, [entitie.value.name]: entitie.value.value })
          })
          registration.dataProvided.entities = entities
        }
        if (this.registrationForm.dataProvided.attrs.values.length > 0) {
          registration.dataProvided.attrs = this.registrationForm.dataProvided.attrs.values
        }
        if (this.registrationForm.dataProvided.expressionz) {
          registration.dataProvided.expression = this.registrationForm.dataProvided.expressionz
        }
        if (this.registrationForm.forwardingInformation.timesSent) {
          registration.forwardingInformation.timesSent = this.registrationForm.forwardingInformation.timesSent
        }
        if (this.registrationForm.forwardingInformation.lastForwarding) {
          registration.forwardingInformation.lastForwarding = this.registrationForm.forwardingInformation.lastForwarding
        }
        if (this.registrationForm.forwardingInformation.lastFailure) {
          registration.forwardingInformation.lastFailure = this.registrationForm.forwardingInformation.lastFailure
        }
        if (this.registrationForm.forwardingInformation.lastSuccess) {
          registration.forwardingInformation.lastSuccess = this.registrationForm.forwardingInformation.lastSuccess
        }
        return registration
      },
      set (regist) {
        const registForm = JSON.parse(JSON.stringify(registration))
        if (regist.description) {
          registForm.description = regist.description
        }
        if (regist.status) {
          registForm.status = regist.status
        }
        if (regist.expires) {
          registForm.expires = regist.expires
        }
        if (regist.provider && regist.provider.http && regist.provider.http.url) {
          registForm.provider.url = regist.provider.http.url
        }
        if (regist.provider && regist.provider.supportedForwardingMode) {
          registForm.provider.supportedForwardingMode = regist.provider.supportedForwardingMode
        }
        if (regist.dataProvided && regist.dataProvided.entities) {
          let entities = []
          for (const entitie of regist.dataProvided.entities) {
            let key = {}
            let value = {}
            if (entitie.id) {
              key.name = 'id'
              key.value = entitie.id
            }
            if (entitie.idPattern) {
              key.name = 'idPattern'
              key.value = entitie.idPattern
            }
            if (entitie.type) {
              value.name = 'type'
              value.value = entitie.type
            }
            if (entitie.typePattern) {
              value.name = 'typePattern'
              value.value = entitie.typePattern
            }
            entities.push({ key: key, value: value })
          }
          registForm.dataProvided.entities = entities
          // for (const [key, value] of Object.entries(subsc.subject.condition.expression)) {}
        }
        if (regist.dataProvided && regist.dataProvided.attrs) {
          registForm.dataProvided.attrs.values = regist.dataProvided.attrs
        }
        if (regist.dataProvided && regist.dataProvided.expression) {
          registForm.dataProvided.expressionz = regist.dataProvided.expression
        }
        this.registrationForm = registForm
      }
    }
  },
  created () {
  }
}
</script>
